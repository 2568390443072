import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

// import { CareersGreenhouse } from "src/sections/careers"
import { CareersGrid } from "src/sections/careers"

const CareerPage = ({ data: { career } }) => {
  const { careers_grid } = useContentfulSection(career.sections)

  const meta = useMeta(career)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <CareersGrid {...careers_grid} />
        {/* <CareersGreenhouse {...careers_grid} /> */}
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    career: contentfulPage(pageId: { eq: "career" }) {
      ...Page
    }
  }
`

export default CareerPage
