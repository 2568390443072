import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"
import { useCreateScriptSnippet } from "src/utils"

// https://app4.greenhouse.io/jobboard/integration/documentation/embedded_board_and_apps

const CareersGreenhouse = () => {
  const [isPositionPage, setIsPositionPage] = useState(false)

  useCreateScriptSnippet({
    url: "https://boards.greenhouse.io/embed/job_board/js?for=backboneai",
    async: false,
  })

  const goBackRefresh = (e) => {
    e.preventDefault()

    if (typeof window !== "undefined") {
      window.location = "/careers"
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(document.location.search)
      const positionId = params.get("gh_jid")

      if (positionId) {
        setIsPositionPage(true)
      } else {
        setIsPositionPage(false)
      }
    }
  }, [])

  return (
    <StyledCareersGreenhouse>
      <VerticalSection
        align="left"
        custom={() => (
          <>
            {isPositionPage && (
              <div className="backWrap">
                <a className="back" href="/careers" onClick={goBackRefresh}>
                  Back
                </a>
              </div>
            )}

            <div id="grnhse_app" />
          </>
        )}
      />
    </StyledCareersGreenhouse>
  )
}

const StyledCareersGreenhouse = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-max-width: 1080px;

  .customWrap {
    margin-top: 0;

    .backWrap {
      margin-bottom: 16px;
    }
  }
`

export default CareersGreenhouse
