import React, { useCallback, useContext } from "react"
import styled from "styled-components"
import map from "lodash/map"

import { VerticalSection, ValuePropRow } from "components/common"

import { Context as GreenhouseContext } from "src/utils/context/greenhouse"

const CareersGrid = ({ title }) => {
  const {
    state: { jobs },
  } = useContext(GreenhouseContext)

  const renderCareersGrid = useCallback(() => {
    return (
      <JobBoard>
        {map(jobs, (positions, department) => {
          return (
            <div className="departmentCol" key={department}>
              <h3 className="department">{department}</h3>

              <div className="careerGrid">
                {map(
                  positions,
                  ({ internal_job_id, title, location, absolute_url }) => (
                    <ValuePropRow
                      key={internal_job_id}
                      href={absolute_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>
                        <p>{title}</p>
                        <p className="small location">{location.name}</p>
                      </div>
                    </ValuePropRow>
                  )
                )}
              </div>
            </div>
          )
        })}
      </JobBoard>
    )
  }, [jobs])

  return (
    <StyledCareersGrid>
      <VerticalSection
        title={title}
        align="left"
        titleAs="h1"
        custom={renderCareersGrid}
      />
    </StyledCareersGrid>
  )
}

const StyledCareersGrid = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-max-width: 1080px;

  .customWrap {
    margin-top: var(--sp-64);
  }
`

const JobBoard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-48);

  .departmentCol {
    .department {
      margin-bottom: var(--sp-32);
    }

    .careerGrid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-16);
      .valuePropRow {
        p {
          font-weight: 500;
          &.location {
            font-weight: normal;
          }
        }
      }
    }
  }
`

export default CareersGrid
